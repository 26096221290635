<script setup lang="ts">
export interface Region {
  name: string
  slug: string
  thumbnail: {
    src: string
    alt: string
  }
}

export type RegionBigSliderProps = {
  regions: Region[]
}

defineProps<RegionBigSliderProps>()

const is2xlScreen = useMediaQuery('(min-width: 1536px')
const isXlScreen = useMediaQuery('(min-width: 1280px)')
const isMdScreen = useMediaQuery('(min-width: 768px)')
</script>

<template>
  <AlHomeTitleSection caption="Kreative Regionen entdecken">
    <AlPadding>
      <div class="group/region-links flex gap-5">
        <AlLink
          v-for="(region, idx) in regions.filter(
            (_, i) => i < (is2xlScreen ? 4 : isXlScreen ? 3 : isMdScreen ? 2 : 1),
          )"
          :key="region.slug"
          :href="`/region/${region.slug}`"
          class="group/region relative flex h-[32rem] flex-grow-[2] basis-1 flex-col items-start justify-end overflow-hidden px-6 py-10 transition-[flex-grow] duration-500 ease-in-out hover:!flex-grow-[4]"
          :class="{ 'flex-grow-[4] group-hover/region-links:flex-grow-[2]': idx === 0 }"
        >
          <img
            :src="region.thumbnail.src"
            :alt="region.thumbnail.alt"
            class="absolute inset-0 h-full w-full object-cover"
          />

          <span
            class="relative translate-y-10 hyphens-auto font-sans-header text-3xl uppercase text-white shadow-gray-500 transition-transform ease-out text-shadow-lg group-hover/region:translate-y-0"
          >
            {{ region.name }}
          </span>

          <AlSlideoutButton
            variant="primary"
            class="relative origin-left scale-75 opacity-0 transition-opacity group-hover/region:opacity-100"
          >
            Mehr erfahren
          </AlSlideoutButton>
        </AlLink>
      </div>
    </AlPadding>
  </AlHomeTitleSection>
</template>
